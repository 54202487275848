import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import gabatop from './images/pain killer/gabatop-100-mg-capsules.jpg'
import gabatop600 from './images/pain killer/Gabatop-600.jpg'
import gabatop800 from './images/pain killer/gabatop-800mg-tab.jpg'
import hqstar200 from './images/pain killer/hq-star-200.jpg'
import nervigesic from './images/pain killer/nervigesic-pregabalin-300mg-capsules2.jpg'
import nervigesign from './images/pain killer/nervisign-300-preagbalin-capsules.jpg'
import Nervismart from './images/pain killer/Nervismart-450.jpg'
import painosoma from './images/pain killer/painosoma-350mg-tablet.jpg'
import telmeheal40h from './images/blood presure medicine/Telmaheal-40h.jpg'
import telmeheal40amh from './images/blood presure medicine/telmaheal-40mg-amh-tablets.jpg'



import aspadol100mg from './images/tapentadol/aspadol-100-mg.jpg'
import aspadoler200mg from './images/tapentadol/aspadol-er-200mg.jpg'
import Tapaday100mg from './images/tapentadol/Tapaday 100 mg.jpg'
import tapaday200 from './images/tapentadol/tapaday 200.jpg'
import tapadaygold250tablet from './images/tapentadol/tapaday-gold-250-tablet.jpg'


import tianeptine50mgtablets from './images/antidrugs/tianeptine-50mg-tablets.jpg'
import tianeptine_tiatan_12_5 from './images/antidrugs/tianeptine-tiatan-12-5.jpg'
import zopiclone7_5 from './images/antidrugs/zopiclone 7.5 mg.jpg'
import zopiclone_10_mg from './images/antidrugs/zopiclone-10-mg.jpg'
import zopiclone_20_mg from './images/antidrugs/zopiclone-20mg.jpg'
import zopimaxx_25_mg from './images/antidrugs/zopimaxx 25.jpg'


import Glycitop80 from './images/antiDiabeticMedicine/Glycitop-80mg.jpeg'
import phage500mg from './images/antiDiabeticMedicine/phage-500mg-tablet.jpg'
import phage100 from './images/antiDiabeticMedicine/phage1000.jpg'
import siaglide100 from './images/antiDiabeticMedicine/siaglide_100_tablet_15s_420343_0_0.jpg'
import sita50500 from './images/antiDiabeticMedicine/Sita-50500.jpeg'
import sitasmart5010 from './images/antiDiabeticMedicine/Sitasmart-Dapa-5010.jpeg'

























function Main() {
  return (

    <Container>

      <br />
      <br />
      <h1>Pain Killer</h1>

      <br />
      <br />

      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={gabatop} />
            <Card.Body>
              <Card.Title>gabatop 100</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={gabatop600} />
          </Card>
          <Card.Body>
            <Card.Title>gabatop 600</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={gabatop800} />
          </Card>
          <Card.Body>
            <Card.Title>gabatop 800</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={hqstar200} />
          </Card>
          <Card.Body>
            <Card.Title>hqstar200</Card.Title>
          </Card.Body>
        </Col>

      </Row>


      <br />
      <br />

      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={nervigesic} />
            <Card.Body>
              <Card.Title>Nervigesic</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={nervigesign} />
          </Card>
          <Card.Body>
            <Card.Title>gabatop 800</Card.Title>
          </Card.Body>
        </Col>


        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={Nervismart} />
          </Card>
          <Card.Body>
            <Card.Title>Nervismart</Card.Title>
          </Card.Body>
        </Col>

       
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={painosoma} />
          </Card>
          <Card.Body>
            <Card.Title>painosoma</Card.Title>
          </Card.Body>
        </Col>


      </Row>

      <br />
      <br />
      <h1>Blood Pressure</h1>

      <br />
      <br />


      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={telmeheal40h} />
            <Card.Body>
              <Card.Title>Telmeheal40h</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={telmeheal40amh} />
          </Card>
          <Card.Body>
            <Card.Title>Telmeheal40amh</Card.Title>
          </Card.Body>
        </Col>

        <Col>
         
        </Col>

        <Col>
         
        </Col>

      </Row>


      <br />
      <br />
      <h1>Tapendol</h1>

      <br />
      <br />


      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={aspadol100mg} />
            <Card.Body>
              <Card.Title>Aspadol100mg</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={aspadoler200mg} />
          </Card>
          <Card.Body>
            <Card.Title>Aspadoler200mg</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={Tapaday100mg} />
          </Card>
          <Card.Body>
            <Card.Title>Tapaday100mg</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={tapaday200 } />
          </Card>
          <Card.Body>
            <Card.Title>Tapaday200 </Card.Title>
          </Card.Body>
        </Col>

      </Row>
      <Row>

      <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={tapadaygold250tablet } />
          </Card>
          <Card.Body>
            <Card.Title>Tapadaygold250tablet </Card.Title>
          </Card.Body>
        </Col>

      </Row>





      <br />
      <br />
      <h1>Anti Anxiety Drugs</h1>

      <br />
      <br />


      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={tianeptine50mgtablets} />
            <Card.Body>
              <Card.Title>Tianeptine50mgtablets</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={tianeptine_tiatan_12_5} />
          </Card>
          <Card.Body>
            <Card.Title>tianeptine_tiatan_12_5</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={zopiclone7_5} />
          </Card>
          <Card.Body>
            <Card.Title>zopiclone7_5</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={zopiclone_10_mg } />
          </Card>
          <Card.Body>
            <Card.Title>zopiclone_10_mg  </Card.Title>
          </Card.Body>
        </Col>

      </Row>

      <Row>

      <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={zopiclone_20_mg} />
          </Card>
          <Card.Body>
            <Card.Title>zopiclone_20_mg</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={zopimaxx_25_mg } />
          </Card>
          <Card.Body>
            <Card.Title>zopimaxx_25_mg </Card.Title>
          </Card.Body>
        </Col>

      </Row> 


      
      <br />
      <br />
      <h1>Anti Daibetic Drugs</h1>

      <br />
      <br />


      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={Glycitop80} />
            <Card.Body>
              <Card.Title>Glycitop80 </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={phage500mg} />
          </Card>
          <Card.Body>
            <Card.Title>phage500mg</Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={phage100 } />
          </Card>
          <Card.Body>
            <Card.Title>phage100 </Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={siaglide100} />
          </Card>
          <Card.Body>
            <Card.Title>siaglide100</Card.Title>
          </Card.Body>
        </Col>

      </Row>
      <Row>
      <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={sita50500 } />
          </Card>
          <Card.Body>
            <Card.Title>sita50500 </Card.Title>
          </Card.Body>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={sitasmart5010} />
          </Card>
          <Card.Body>
            <Card.Title>sitasmart5010</Card.Title>
          </Card.Body>
        </Col>

      </Row>



    </Container>
  
  );
}

export default Main;